<template>
    <div class="desc-wrapper">
        <div
            v-for="(desc, index) in descriptions"
            :key="index"
            class="desc-container"
        >
            <span class="desc-number">註{{ index + 1 }}：</span>
            <span class="desc" v-html="desc" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Description',
    props: {
        descriptions: {
            type: Array,
            default: function () {
                return []
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.desc-wrapper {
    margin-top: 20px;
    @media screen and (max-width: 576px) {
        margin-top: 0px;
    }
}

.desc-container {
    display: flex;
    color: $secondary-grey;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
}

.desc-number {
    word-break: keep-all;
}
</style>
